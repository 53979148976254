<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pt-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 lg3 pb-2 class="nsbold text-left"
              ><span style="font-size: 20px">Apology Report</span></v-flex
            >

            <v-flex xs12 sm3 pb-2>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    clearable
                    hide-details
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 pl-md-4 pb-2 pl-sm-2>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    hide-details
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 pl-lg-4 pb-2 pl-sm-2>
              <v-text-field
                v-model="keyword"
                dense
                label="Search : Ad.no/Name"
                outlined
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3 lg12 pl-lg-4 pb-2 pl-sm-2 pt-lg -2 text-right>
              <download-excel :data="myData" :fields="json_fields">
                <v-btn
                  width="155px"
                  dark
                  style="text-transform: none"
                  color="#766BC0"
                  class="hidden-xs-only"
                  >Download Excel</v-btn
                >
                <v-btn
                  width="155px"
                  dark
                  block
                  class="hidden-sm-and-up"
                  style="text-transform: none"
                  color="#766BC0"
                  >Download Excel</v-btn
                >
              </download-excel>
            </v-flex>
            <!-- <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex> -->
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="outpass" pt-2>
              <v-layout wrap py-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="outpass.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <th class="text-left">Date</th>
                          <th class="text-left">Reason</th>
                          <!-- <th class="text-left">Purpose</th> -->
                          <th class="text-left">View</th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in outpass" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                              {{ item.studentid.name }}
                            </router-link>
                          </td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.create_date">
                              {{ formatDate(item.create_date) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.toTime">
                              {{ formatDate(item.toTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->
                          <td>
                            <span v-if="item.reason">
                              {{ item.reason }}
                            </span>

                            <span v-else>-</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.permissionStatus">
                              <v-icon
                                small
                                style="cursor: pointer"
                                title="View Reason"
                                >mdi-file-document-outline</v-icon
                              >
                            </span>
                            <span v-else-if="item.permissionStatus == ''">-</span>
                            <span v-else>{{ item.permissionStatus }}</span>
                          </td> -->
                          <!---------------------------------------------------------------------->
                          <td>
                            <v-btn
                              v-if="item.isApology == false"
                              small
                              outlined
                              disabled
                              color="#766BC0"
                              @click="(createdialogue = true), (apology = item)"
                            >
                              <!-- <v-icon small>mdi-plus</v-icon> Add Apology -->No
                              apology found
                            </v-btn>
                            <v-btn
                              v-if="item.isApology == true"
                              small
                              outlined
                              color="#766BC0"
                              @click="
                                (viewdialogue = true),
                                  (apology2 = item._id),
                                  getApology((id = apology2))
                              "
                            >
                              <v-icon small>mdi-plus</v-icon> View Apology
                            </v-btn>
                          </td>
                          <!---------------------------------------------------------------------->
                          <!-- <td>
                            <div v-if="item.permissionStatus == 'Pending'">
                              <v-btn outlined small @click="approveData(item._id)">
                                Approve
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-btn small disabled text outlined plain>
                                Approved
                              </v-btn>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog width="400px" v-model="approvedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to Approve?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="approvedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="approveData()"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="rejectdialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text">Enter reject reason </v-card-text>
        <v-flex xs12 px-4 py-2>
          <v-text-field v-model="reson" outlined dense></v-text-field>
        </v-flex>

        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="rejectdialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="rejectData()"
            >Reject</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="form3">
      <v-dialog width="400px" v-model="viewdialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title>Incident Description</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap pa-4>
            <v-flex xs12 pa-1>
              <v-img height="400px" contain :src="mediaURL + photo"> 
              
                <v-btn color="#766BC0" @click="onDownloadClick(photo)"
><v-icon>mdi-download</v-icon>
</v-btn> 
              </v-img>
            </v-flex>
          </v-layout>

          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="viewdialogue = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      photo: null,
      reson: null,
      // download excel
      myData: [],
      excel: {
        adno: "",
        name: "",
        class: "",
        date: "",
        reason: "",
      },
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        Date: "date",
        Reason: "reason",
      },
      //
      //    issueStatus : ["All", "Out", "Issued", "In"],
      //   cvalue: "All",
      approvedialoge: false,
      rejectdialoge: false,
      viewdialogue: false,
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      outpass: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
    //  this.myData = [];
    this.getExcel();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
      this.getExcel();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
    // cvalue() {
    //   if(this.page>1)
    //   this.page=1
    //   this.getData();
    // },
  },
  methods: {

    
    onDownloadClick(item) {
      axios({
        method: "POST",
        url: "/apology/getlist",
        data: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      console.log(item);
      var fileUrl = "https://chavara.leopardtechlabs.in/file/get/" + item;
      // console.log(fileUrl);
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.download = "image.jpeg";
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/apology/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   issueStatus : a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;

            //    for(let i=0; i<this.outpass.length; i++)
            //    {
            //      if(this.outpass[i].issueStatus == "Active")
            //      {
            //         this.outpass[i].issueStatus = "Issued";
            //      }
            //    }

            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getExcel() {
      this.myData = [];
      this.appLoading = true;
      axios({
        method: "get",
        url: "/apology/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          //   issueStatus : a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.myData = [];
            this.outpass = response.data.data;
            //excel
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              if (response.data.data[i].studentid.Class) {
                this.excel.class =
                  response.data.data[i].studentid.Class +
                  "-" +
                  response.data.data[i].studentid.division;
              }
              if (response.data.data[i].day) {
                this.excel.date = this.formatDate(response.data.data[i].day);
              }
              if (response.data.data[i].reason) {
                this.excel.reason = response.data.data[i].reason;
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getApology() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/incident/report/get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.apology2,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.photo = response.data.data.photo;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    approveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/studypermission/approvedby/warden",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    rejectData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/permission/rejectby/warden",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
          rejectReason: this.reson,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rejectdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
